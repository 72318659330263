var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

global.jQuery = require('jquery');
var slickInit = require('slick-carousel');

page_home = {
	load: function(){
		this.homeSlider();
		this.homeCosaSlider();
		this.homeSettSlider();
	},
	homeSlider: function(){
		jQuery('.homeVisual__slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			speed: 1000,
			touch: false,
			autoplay: true,
			autoplaySpeed: 5000,
			asNavFor: '.homeVisual__thumbWrap'
		});

		jQuery('.homeVisual__thumbWrap').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			arrows: false,			
			speed: 1000,
			touch: false,
			asNavFor: '.homeVisual__slider',
			responsive: [
			    {
			      breakpoint: 1400,
			      settings: {
			        slidesToShow: 3,
					slidesToScroll: 1,
					arrows: false,			
					speed: 1000,
					touch: true,
			        
			      }
			    },
			      {
			      breakpoint: 1024,
			      settings: {
			        slidesToShow: 2,
					slidesToScroll: 1,
					arrows: false,			
					speed: 1000,
					touch: true,
			        
			      }
			    },
			    {
			      breakpoint: 767,
			      settings: {
			        slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,			
					speed: 1000,
					touch: true,
			        
			      }
			    },
			  ]
		});

		$('.homeVisual__thumb').on('click', function(){
			siteGlobal.lazyLoad();
			var slideNum = $(this).data('thumb');
			$('.homeVisual__thumb').removeClass('slick-current');
			$(this).addClass('slick-current');
			jQuery('.homeVisual__slider').slick('slickGoTo', slideNum);
		})

		jQuery('.homeVisual__slider').on('beforeChange', function(){
			$('.homeVisual__title').addClass('changingTitle');
			siteGlobal.lazyLoad();
		});
		jQuery('.homeVisual__slider').on('afterChange', function(){
			var currentSlide = jQuery('.homeVisual__slider').slick('slickCurrentSlide');
			$('.homeVisual__title').removeClass('changingTitle');
			$('.homeVisual__thumb').removeClass('slick-current');
			$('.homeVisual__thumb[data-thumb="'+currentSlide+'"]').addClass('slick-current');

		});
	},
	homeCosaSlider: function(){
		jQuery('.homeCosa__gal').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			speed: 500,
			touch: false,
			autoplay: true,
			autoplaySpeed: 5000,
		});
	},
	homeSettSlider: function(){
		jQuery('.homeSett__gall').each(function(){		
			jQuery(this).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: true,
				speed: 500,
				touch: false,
				autoplay: true,
				autoplaySpeed: 5000
			});
		})
	},
}

$(window).on('load', function(){
	page_home.load();
})